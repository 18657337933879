@font-face {
  font-family: "WorkSans-Bold";
  src: url("/fonts/WorkSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("/fonts/OpenSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("/fonts/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-Bold";
  src: url("/fonts/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "LibreFranklin-SemiBold";
  src: url("/fonts/librefranklin-semibold.ttf") format("truetype");
}
@font-face {
  font-family: "LibreFranklin-Regular";
  src: url("/fonts/librefranklin-regular.ttf") format("truetype");
}
@font-face {
  font-family: "LibreFranklin-Bold";
  src: url("/fonts/librefranklin-bold.ttf") format("truetype");
}

body {
  margin: 0 -15px 0 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
