/* TOP SECTION */
.title-container-layout {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-cointainer {
  z-index: 2;
  font-size: 80px;
  white-space: normal;
  font-family: "Work Sans", Helvetica, Arial, serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -2.5px;
  color: #000000;
  line-height: 80px;
  transform: translate(117px, -60px);
  height: fit-content;
}
.title {
  white-space: normal;
  font-size: 65px;
  margin-top: 0;
  display: table-cell;
  vertical-align: middle;
  line-height: 60px;
  padding-top: 20px !important;
  width: 450px;
}
.home-title {
  word-wrap: break-word;
  overflow-wrap: break-word;
  z-index: 1;
  background-color: white;
}

.title-cointainer .content {
  min-width: 500px;
}
.feature-margin {
  width: 30%;
}
.featured-title {
  font-family: "OpenSans-Regular", Helvetica, Arial, serif;
  font-size: 16px;
  color: black;
  text-align: left;
  letter-spacing: 5px;
}

.featured-count {
  font-family: "OpenSans-Regular", Helvetica, Arial, serif;
  font-size: 16px;
  color: #949494;
  text-align: left;
  text-decoration: none;
}
.featured-count:hover {
  color: #949494;
  text-decoration: underline;
  color: #949494;
}
.dots-home {
  height: 35vw;
  position: absolute;
  z-index: 4;
}

/* PROP LIST SECTION */
.home-property-container {
  background-color: rgb(245, 245, 245);
  text-decoration: none;
  padding-bottom: 100px;
}
.selected-property {
  pointer-events: none;
  right: 0;
  z-index: 0;
}
.selected-property img {
  min-height: 400px;
  height:10vh;
  transform: translateX(12vw);
  z-index: 0;
}
.selected-property .limit{
  min-height: 300px!important;
}
.selected-property .one {
  z-index: 0;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 1;
  clip-path: inset(0% 0%); 
  transition: all 0.8s ease-out;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}
.selected-property .two {
  z-index: 0;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 1;
  clip-path: inset(0% 0%); 
  transition: all 0.8s ease-out;
  animation: 1s ease-out 0s 1 slideInFromLeftTwo;
}
@keyframes slideInFromLeft {
  0% { clip-path: inset(0% 0% 0% 100%); }
  100% { clip-path: inset(0% 0%); }
}
@keyframes slideInFromLeftTwo {
  0% { clip-path: inset(0% 0% 100% 0%);  }
  20% { clip-path: inset(0% 0% 100% 0%);  }
  100% { clip-path: inset(0% 0%); }
}
.section-name {
  font-family: "OpenSans-SemiBold", Helvetica, Arial, serif;
  font-size: 16px;
  color: rgba(148, 148, 148, 1);
  line-height: 28px;
  padding-bottom: 15px;
  border-bottom: rgba(148, 148, 148, 0.6);
  border-bottom-style: solid;
  border-width: 1px;
  padding-top: 40px;
  padding-left: 0px;
  padding-right: 0px;
}

.section-region {
  font-family: "OpenSans-SemiBold", Helvetica, Arial, serif;
  font-size: 20px;
  color: black;
  line-height: 28px;
  margin-top: 50px;
}

.section-item {
  z-index: 1;
  position: relative;
  margin-top: 30px;
  transition: transform 0.3s ease-out;
}
.section-item:hover {
  transform: translateX(15px);
  color: #f5c251;
}
.section-item:hover img {
  transform: translate(15px, -2px);
  opacity: 1;
}
.section-item a:hover {
  color: #f5c251;
}
.section-item a {
  font-family: "OpenSans-Bold", Helvetica, Arial, serif;
  font-size: 30px;
  color: black;
  line-height: 28px;
  text-transform: uppercase;
  text-decoration: none;
}
.section-item a span.tag {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  padding: 3px 4px;
  line-height: 1;
  margin: 0 0 0 10px;
  position: relative;
  top: -4px;
}
span.tag.selling {
  color: #282827;
  background-color: #F5C251;
}
span.tag.leasing {
  color: #F5C251;
  background-color: #282827;
}
.section-item img {
  margin-left: 10px;
  opacity: 0;
  transition: all 0.3s ease-out;
}
.hide {
  opacity: 0;
  transform: scale(0);
  width: 0px;
  margin-right: 0px;
}
.title-policy {
  text-transform: capitalize !important;
  padding-bottom: 50px;
}
/* WHO WE ARE */
.about-section {
  background-color: white;
  padding: 70px 0 120px 0;
}
.about-section .title {
  font-family: "OpenSans-Bold", Helvetica, Arial, serif;
  font-size: 30px;
  color: black;
  line-height: 28px;
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: 30px;
}
.about-section .description {
  font-family: "OpenSans-Regular", Helvetica, Arial, serif;
  font-size: 16px;
  color: rgba(148, 148, 148, 1);
  line-height: 28px;
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
}
.about-section .video-container {
  margin-left: 10%;
  margin-right: 10%;
  position: relative;
}
.video-container .play-button {
  cursor: pointer;
  width: 85px;
  height: 85px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-container .play-button:hover {
  opacity: 0.8;
}
.about-section .video-container .poster {
  width: 100vw;
  height: 35vw;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}
.about-section .video-container video {
  width: 100%;
  position: relative;
  z-index: 1;
}
.about-section .dots-video {
  height: 310px;
  width: 310px;
  z-index: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(19%, 19%);
}
@media only screen and (max-width: 768px) {
  .title-container-layout {
    display: inline;
  }
  .about-section .video-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .about-section .video-container .poster {
    width: 100vw;
    height: 55vw;
  }
  .about-section .description {
    margin-left: 16px;
    margin-right: 16px;
  }
  .title-cointainer {
    position: relative;
    margin-top: 10px;
    margin-left: 45px;
    width: 80vw;
    font-size: 50px;
    line-height: 48px;
    transform: translate(0px, 0px);
  }
  .title {
    word-wrap: break-word;
    min-width: initial !important;
    line-height: 30px;
  }
  .home-title {
    padding: 0px 35px 20px 35px !important;
    z-index: 3;
    background-color: white;
  }
  .dots-home {
    z-index: 4;
    height: 110px;
  }
  .dots-video {
    height: 100px !important;
    transform: translate(0, 30px) !important;
  }
  .home-title .content {
    min-width: auto;
    transform: translateX(0px);
  }
  .title-policy {
    text-transform: capitalize !important;
    padding-bottom: 10px;
  }
}
