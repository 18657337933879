button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.carousel-container {
  overflow: hidden;
  display: inline!important;
}
.carousel-container img.img-fluid {
  display: none;
}

.carousel-container img.active {
  display: block;
}

.carousel-gradient {
  top: 0;
  height: 100%;
  bottom: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  background-image: linear-gradient(
    to left,
    rgba(122, 141, 173, 0) 50%,
    rgba(122, 141, 173, 0.73) 95%
  );
}

.carousel-control-prev {
  width: 42px;
  height: 30px;
  top: unset;
  background-color: black;
  opacity: 1;
  border: none;
}
.carousel-control-prev img {
  left: 0;
  transform: rotate(180deg);
}

.carousel-control-next {
  width: 42px;
  height: 30px;
  margin-left: 42px;
  top: unset;
  left: 0;
  justify-content: left;
  background-color: black;
  opacity: 1;
  border: none;
}
.carousel-control-next img {
  left: 0;
}
.carousel-bottom-controls {
  /* position: absolute;
  width: 100%; */
}
.test-card-container {
  overflow: hidden;
  background-color: transparent;
}
.test-card-wrapper {
  z-index: 2;
  transform: translateY(-150px);
  text-decoration: none;
  background-color: transparent;
  padding-left: 8px;
  padding-right: 8px;
}
.test-card-wrapper :hover {
  text-decoration: none;
}
.test-card {
  background-color: transparent;
  overflow: hidden;
}

.test-card img {
  width: 160px;
  height: 140px;
  object-fit: cover;
}
@media only screen and (max-width: 767px) {
  .test-card img {
    width: 75px;
    height: auto;
  }
  div.carousel-card-container div.todo-item {
    width: 90px;
    height: auto;
  }
}
.carousel-bottom-controls {
}

.active-card {
  border: 4px solid white;
}
.inactive-card {
  padding: 4px;
}
.featured-controls-mobile {
  display: none !important;
}
.carousel-card-container {
  height: 50px;
}
.carousel-card-container span {
  display: flex;
  margin-left: auto;
}

/* ANIMATIONS */
.carousel-enter {
  transform: translateX(100%);
  position: absolute;
}

.carousel-enter.carousel-enter-active {
  transform: translateX(0%);
  transition: all 1000ms ease-in;
}

.carousel-leave {
  transform: translateX(0%);
}

.carousel-leave.carousel-leave-active {
  transform: translateX(-100%);
  transition: all 1000ms ease-in;
}
.pop-enter {
  opacity: 0.01;
  transform: translate(100%, -50px);
}

.pop-enter.pop-enter-active {
  opacity: 1;
  transform: translate(0%, -50px);
  transition: all 1000ms ease-in;
}

.pop-leave {
  transform: scaleX(1) translateY(-50px);
  opacity: 1;
}

.pop-leave.pop-leave-active {
  transform-origin: 0% -50%;
  transform: scale(0) translate(-200%, -50px);
  opacity: 0;
  transition: all 1000ms ease-in;
}

/* REVERSE ANIMATIONS */

.carousel-reverse-enter {
  transform: translateX(-100%);
  position: absolute;
}

.carousel-reverse-enter.carousel-reverse-enter-active {
  transform: translateX(0%);
  transition: all 1000ms ease-in;
}

.carousel-reverse-leave {
  transform: translateX(0%);
}

.carousel-reverse-leave.carousel-reverse-leave-active {
  transform: translateX(100%);
  transition: all 1000ms ease-in;
}

.pop-reverse-enter {
  opacity: 1;
  transform: translate(0%, -50px);
}

.pop-reverse-enter.pop-reverse-enter-active {
  opacity: 0.01;
  transform: translate(100%, -50px);
  transition: all 1000ms ease-in;
}

.pop-reverse-leave {
  transform: scale(0) translateY(-50px);
  opacity: 0;
}

.pop-reverse-leave.pop-reverse-leave-active {
  transform-origin: -100% -50%;
  transform: scale(1) translateY(-50px);
  opacity: 1;
  transition: all 1000ms ease-in;
}
.todo-item {
  width: 184px;
  height: 144px;
  float: left;
  /* margin-right: 15px; */
}

.card-slide-enter {
  opacity: 0.01;
  width: 0px;
}

.card-slide-enter.card-slide-enter-active {
  opacity: 1;
  width: 184px;
  transition: 1000ms ease-in;
}

.card-slide-leave {
  opacity: 1;
  width: 184px;
}

.card-slide-leave.card-slide-leave-active {
  opacity: 0.01;
  width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  transition: 1000ms ease-in;
}
@media only screen and (max-width: 768px) {
  .pop-reverse-leave.pop-leave-active {
    transform: scale(1) translate(-950px, 0px);
  }
  .test-card-wrapper {
    transform: translateY(-50px);
  }
  .carousel-bottom-controls .featured-title {
    display: none;
  }
  .carousel-bottom-controls .featured-count {
    display: none;
  }
  .featured-controls-mobile {
    display: block !important;
    margin-left: 36px;
  }
  .test-card-container {
    margin-left: 30px;
  }
  .carousel-buttons {
    display: none;
  }
  .carousel-card-container span {
    margin-left: 0px;
  }
  .test-card{
    display: initial!important;
  }
}
