/* COMMON */
.dots {
  background-image: radial-gradient(#cecece 23%, transparent 20%);
  background-position: 0 0, 8px 8px;
  background-size: 14px 14px;
  width: 40px;
}
.content-app {
  margin-top: 20px;
}
.navbar-brand {
  margin: 0;
  width: 182px;
  height: 55px;
}
.navbar-brand img {
  width: 100%;
  height: auto;
}
/* HERO */
.heading {
  font-family: "OpenSans-SemiBold", Helvetica, Arial, serif;
  font-size: 16px;
  color: rgba(40, 40, 39, 1);
  line-height: 28px;
}
.title {
  font-family: "Work Sans", Helvetica, Arial, serif;
  font-weight: 700;
  font-style: normal;
  font-size: 65px;
  color: rgba(40, 40, 39, 1);
  text-align: left;
  letter-spacing: -2.03px;
  text-transform: uppercase !important;
  white-space: pre-wrap;
  line-height: 52px;
  padding-top: 30px !important;
  margin-left: -2.03px;
}
.subtitle {
  font-size: 20px !important;
  line-height: 30px;
  padding-top: 15px !important;
}
.description {
  font-family: "OpenSans-Regular", Helvetica, Arial, serif;
  font-size: 14px;
  color: rgba(111, 111, 111, 1);
  text-align: left;
  line-height: 28px;
  padding-top: 15px !important;
}

/* DETAILS */
.details-title {
  font-family: "OpenSans-SemiBold", Helvetica, Arial, serif;
  font-size: 16px;
  color: #282827;
  line-height: 28px;
  text-transform: capitalize;
  text-decoration: none;
  margin-top: 50px;
  padding-bottom: 15px;
  border-bottom: #282827;
  border-bottom-style: solid;
  border-width: 1px;
}

.detail-item-heading {
  font-family: "OpenSans-SemiBold", Helvetica, Arial, serif;
  font-size: 14px;
  color: #000000;
  line-height: 28px;
  text-transform: uppercase;
}

.detail-item-data {
  font-family: "OpenSans-Regular", Helvetica, Arial, serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
  line-height: 28px;
}

.detail-item-link {
  font-family: "OpenSans-SemiBold", Helvetica, Arial, serif;
  font-size: 16px;
  color: #000000;
  line-height: 28px;
  text-decoration: underline;
}
/* TRANSITION ANIM */
.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
@media only screen and (max-width: 768px) {
  .content-app {
    margin-top: 0;
  }
  .dots {
    background-image: radial-gradient(#afafaf 10%, transparent 30%);
    background-position: -2px -2px, 1px 1px;
    background-size: 10px 10px;
    width: 30px;
  }
  .heading {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 25px;
  }
  .title {
    font-size: 30px !important;
    line-height: 30px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 15px !important;
  }
  .subtitle {
    font-size: 18px !important;
    line-height: 30px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 15px !important;
  }
  .description {
    padding-left: 16px;
    padding-right: 16px;
  }
  .details-title {
    margin-left: 16px;
    margin-right: 16px;
  }
  .detail-item-heading {
    margin-left: 16px;
    margin-right: 16px;
  }
  .detail-item-data {
    margin-left: 16px;
    margin-right: 16px;
  }
  .detail-item-link {
    margin-left: 16px;
    margin-right: 16px;
  }
  .hero img {
    margin-left: 16px;
    padding-right: 48px;
  }
}
