.back-nav a {
  font-family: "OpenSans-SemiBold", Helvetica, Arial, serif;
  font-size: 16px;
  color: #282827;
  line-height: 28px;
  text-transform: capitalize;
  text-decoration: none;
}
.back-nav a:hover {
  text-decoration: none;
}
.detail-heading img{
  width: 40px;
  height: 40px;
  margin-right: 25px;
  display: none;
}
.detail-heading {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
}
.property-details .title {
  text-transform: capitalize !important;
  margin-bottom: 0.15em;
  display: block;
  width: 100%;
}
.property-details .subtitle {
  font-family: "OpenSans-SemiBold", Helvetica, Arial, serif;
  text-transform: capitalize !important;
  margin-bottom: 1.4em!important;
  display: block;
  width: 100%;
  font-weight: 600;
  font-size: 30px!important;
}
.property-details .description {
  padding-top: 0 !important;
}
.dots-property {
  height: 320px;
  width: 320px;
  position: absolute;
  transform: translate(78%, -82%);
  z-index: -1;
}
.detail-img-wrapper {
  margin-left: 1.5rem;
  overflow: hidden;
}
.detail-img-wrapper > div {
}
div.carousel-container {
  position: relative;
}
div.carousel-container div.dots {
  width: 440px;
  height: 440px;
  position: absolute;
  right: -100px;
  bottom: -40px;
  z-index: 0;
}
div.carousel {
  width: 100vw;
  margin-top: 40px;
  margin-bottom: 80px;
  margin-left: 20px;
  position: relative;
  z-index: 1;
}
div.carousel .flickity-viewport {
  /*overflow: visible;*/
}
div.carousel-cell {
  height: 445px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
}
div.carousel-cell img {
  width: auto;
  height: 100%;
  box-shadow: 0 20px 50px 0 rgba(0,0,0,0.26);
}
.leasing-btn a{
  border-radius: 0!important;
  padding: 10px 55px;
  border-style: none;
}
.leasing-btn a:hover{
  color: #282827;
  background-color: #F5C251;
}
div.flickity-slider {
  left: -164px !important;
}
.flickity-page-dots {
  bottom: -40px;
}
.flickity-page-dots .dot {
  background: rgba(94,88,140,0.76);
  opacity: 1;
}
.flickity-page-dots .dot.is-selected {
  background: rgba(194,149,52,0.65);
}
.property-details #map {
  height: 250px;
  margin-top: 50px;
  position: relative;
}
.property-details #map .gmnoprint,
.property-details #map .gm-style-cc,
.property-details #map a[title="Open this area in Google Maps (opens a new window)"] {
  display: none !important;
}
.property-details #map > div > div > div {
  cursor: default !important;
}
@media all and (min-width: 769px) {
  div.row.details > div.mt-4:nth-child(-n+2) {
    margin-top: 0 !important;
  }
}
@media all and (max-width: 1300px) {
  div.carousel-cell {
    width: 66%;
  }
}
@media all and (max-width: 1200px) {
  div.carousel-cell {
    width: 70%;
  }
}
@media all and (max-width: 1100px) {
  div.carousel-cell {
    width: 80%;
  }
}
@media all and (max-width: 1024px) {
  div.carousel {
    margin-left: 0;
  }
  div.carousel-cell {
    width: 100%;
    margin-right: 0;
  }
  div.flickity-slider {
    left: 0 !important;
  }
}
@media only screen and (max-width: 768px) {
  .detail-heading {
    margin-left: 16px;
    margin-top: 20px;
  }
  .back-nav {
    padding-left: 16px;
    padding-right: 16px;
  }
  .detail-img-wrapper {
    margin-left: 0;
    padding-top: 25px;
  }
  .dots-property {
    height: 320px;
    width: 90%;
    position: absolute;
    transform: translate(0%, -90%);
    z-index: -1;
  }
  div.carousel {
    width: 100%;
  }
  div.carousel-cell {
    height: 250px;
  }
  .property-details {
    padding-top: 2em;
  }
  .property-details .subtitle {
    margin-bottom: 1.4em!important;
    font-size: 20px!important;
  }
  div.carousel-container div.dots {
    width: 200px;
    height: 200px;
    bottom: 30px;
  }
  div.row.details {
    margin-top: 0.5em;
  }
}
