.sticky-top {
  background-color: white;
}
.navbar {
  background-color: white;
  height: 100px;
  z-index: 3;
  padding-left: 0;
  padding-right: 0;
}
.nav-item {
  background-color: white;
}
.nav-link {
  color: #282827 !important;
  font-family: "OpenSans-SemiBold";
  font-style: normal;
  font-size: 16px;
}
.active-nav > a {
  border-bottom: 2px solid;
}
.active-nav .nav-link {
  font-family: "OpenSans-Bold";
}
.navbar-collapse {
  background-color: white;
}
.dropbtn {
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 2;
  padding-left: 4px;
  padding-right: 4px;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  background-color: rgba(0, 0, 0, 1);
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px 20px 30px 20px;
}

.dropdown-content a {
  color: black;
  padding: 6px 0 6px 0;
  text-decoration: none;
  display: block;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
}

.dropdown-content a span.tag {
  display: inline-block;
  margin: 0 0 0 10px;
  padding: 2px 3px;
  text-transform: uppercase;
  font-size: 9.6px;
  font-weight: 700;
  line-height: 1;
}

.dropdown-content a span.tag.leasing {
  color: #282827;
  background-color: #F5C251;
}

.dropdown-content .special {
  color: rgba(245, 194, 81, 1);
  text-decoration: underline;
  margin-top: 10px;
}

.dropdown-content a:hover {
  text-decoration: underline;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .dropbtn {
}

.dropdown-section-heading {
  font-family: "Open Sans", Helvetica, Arial, serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  color: white;
  border-bottom: 1px solid #f5c251;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.nav-link .fas {
  font-size: 0.6em;
  margin: auto 0 auto 5px;
  padding-bottom: 3px;
  vertical-align: middle;
}
.nav-menu-heading {
  display: none;
  font-family: "OpenSans-Bold";
  font-size: 30px;
  padding-bottom: 20px;
  padding-top: 20px;
}
.only-mobile {
  display: none;
}
.hamburger {
  padding: 0px;
  margin-top: 5px;
  margin-right: 20px;
}
@media only screen and (max-width: 768px) {
  .navbar-wrapper {
    padding: 0 0 0 0;
  }
  .navbar {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    height: initial;
  }
  .navbar a {
    padding-top: 0;
    padding-bottom: 0;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    left: 0;
    background-color: rgba(40, 40, 39, 1);
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px 20px 30px 20px;
  }
  .navbar-collapse {
    padding-bottom: 20px;
    text-align: center;
  }
  .nav-link {
    font-size: 20px;
    padding-top: 30px;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
  }
  .nav-item {
    width: 100%;
  }
  .nav-menu-heading {
    display: block;
  }
  .dropdown-content {
    width: 100%;
    position: relative;
    padding: 20px 30px 40px 30px;
  }
  .only-desktop {
    display: none;
  }
  .only-mobile {
    display: block;
  }
  .navbar-brand img {
    width: auto;
    height: 100%;
  }
}
